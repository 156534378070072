import { trackPulseRecommendationItemClickEvent } from '@smd/tracking';
import { GalleryCard } from './GalleryCard';

export type Car = {
  image: string;
  title: string;
  price: string;
  url?: string;
};

interface IGalleryProps {
  title?: string;
  cars: Car[];
  recommendationItem?: string;
}

export const Gallery = ({ cars, recommendationItem }: IGalleryProps) => {
  const generateTrackingOnClick = (car: Car, rank: number) => () => {
    let eventDescriptionType = '';

    if (recommendationItem === 'KlikXtra') {
      eventDescriptionType = 'Klikxtra';
    } else if (recommendationItem === 'ExtraPromoted') {
      eventDescriptionType = 'ExtraPromoted';
    } else if (recommendationItem === 'Newest') {
      eventDescriptionType = 'Newest';
    }

    trackPulseRecommendationItemClickEvent({
      classifiedAdId: car.url ?? '',
      elementName: 'FrontpageItem',
      eventDescription: eventDescriptionType,
      elementPosition: rank,
    });
  };

  return (
    <>
      {cars.map((car: Car, index) => (
        <GalleryCard
          car={car}
          key={index}
          rank={index + 1}
          onClick={generateTrackingOnClick(car, index + 1)}
        />
      ))}
    </>
  );
};
